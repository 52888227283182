<template>
  <div>
    <CDataTable
      :items="bills"
      :fields="fields"
      :column-filter="{ external: externalFilter }"
      :items-per-page-select="{ label: 'จำนวนแถว' }"
      :table-filter-value.sync="tableFilterValue"
      :column-filter-value.sync="columnFilterValue"
      :items-per-page="itemsPerPage"
      :loading="tableLoading"
      :noItemsView="{
        noResults: 'ไม่พบข้อมูล',
        noItems: 'ไม่พบข้อมูล',
      }"
      v-on:pagination-change="paginationChange"
      v-on:update:column-filter-value="columnFilter"
      :sorter="false"
      pagination
      hover
      dark
      border
      size="sm"
      add-table-classes="text-center"
    >
      <template #more="{ item }">
        <td>
          <b-button
            class="mr-2"
            size="sm"
            v-b-modal.modal-1
            @click="getUserHuayBillItem(item)"
            >เพิ่มเติม</b-button
          >
          <button
            v-if="item.status == 'OPEN'"
            class="btn btn-sm btn-danger"
            @click="cancelBill(item)"
            v-show="canCancelBill"
          >
            คืนโพย
          </button>
        </td>
      </template>
    </CDataTable>
    <CPagination
      :active-page="activePage"
      :double-arrows="false"
      :pages="totalPage"
      :dots="false"
      v-on:update:activePage="updateActivePage"
    />
    <b-modal
      id="modal-1"
      size="lg"
      body-bg-variant="dark"
      body-text-variant="light"
      header-bg-variant="dark"
      header-text-variant="light"
      footer-bg-variant="dark"
      footer-text-variant="light"
      hide-footer
      title="รายการแทง"
    >
      <table
        class="table table-sm table-dark table-bordered table-striped w-100"
      >
        <thead>
          <tr>
            <th>ลำดับ</th>
            <th>#ID</th>
            <th>ประเภท</th>
            <th>เรทจ่าย</th>
            <th>เลข</th>
            <th>ราคาแทง</th>
            <th>ส่วนลด</th>
            <th>ค่าแนะนำ</th>
            <th>ถูกรางวัล</th>
            <th>สถานะ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in huayBillItems" :key="index">
            <td>{{ index + 1 }}.</td>
            <td>{{ item.id }}</td>
            <td>{{ item.huayCategoryName }}</td>
            <td>{{ item.payRate }}</td>
            <td>{{ item.number }}</td>
            <td>{{ util.formatMoneyNormal(item.buyAmount) }}</td>
            <td>{{ item.discountAmount }}</td>
            <td>{{ item.affiliateAmount }}</td>
            <td
              :class="{
                positive: item.status === 'BET_WIN',
              }"
            >
              {{ util.formatMoneyNormal(item.winAmount) }}
            </td>
            <td
              :class="{
                positive: item.status === 'BET_WIN',
                negative: item.status === 'BET_LOSE',
              }"
            >
              {{ item.statusTH }}
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bills: [],
      isLoading: true,
      fields: [
        {
          key: "id",
          label: "#ID",
          _style: "width: 1%",
        },
        {
          key: "huayRoundName",
          label: "หวย",
          filter: false,
        },
        {
          key: "huayPayName",
          label: "อัตราจ่าย",
          filter: false,
        },
        {
          key: "sumBuyAmount",
          label: "ยอดแทง",
          filter: false,
        },
        {
          key: "sumDiscountAmount",
          label: "ส่วนลด",
          filter: false,
        },
        {
          key: "sumAffiliateAmount",
          label: "ค่าแนะนำ",
          filter: false,
        },
        {
          key: "sumWinAmount",
          label: "ถูกรางวัล",
          filter: false,
        },
        {
          key: "numberOfItem",
          label: "รายการแทง",
          filter: false,
        },
        {
          key: "note",
          label: "note",
          filter: false,
        },
        {
          key: "createdAt",
          label: "แทงเมื่อ",
          filter: false,
        },
        {
          key: "status",
          label: "สถานะ",
        },
        {
          key: "more",
          label: "",
          filter: false,
        },
      ],
      tableFilterValue: "",
      columnFilterValue: {},
      sorterValue: {},
      itemsPerPage: 10,
      totalPage: 5,
      activePage: 1,
      tableLoading: false,
      externalFilter: true,
      huayBillItems: [],
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user.userData
    },
    canCancelBill(){
      return this.$store.state.auth.user?.userData?.permissions?.includes("edit_member_agent_cancel_bill") 
    },
  },
  methods: {
    setPage(val) {
      this.$route.query.page = val
    },
    async getUserHuayBillItem(item) {
      this.swal.processing()

      try {
        const { data } = await this.axios({
          method: "post",
          url: "bet/huay-bill-item",
          data: {
            userHuayBillId: item.id,
            huayRoundId: item.huayRoundId,
          },
        })

        const huayBillItems = data.data.huayBillItems

        huayBillItems?.sort(function (a, b) {
          return a.id > b.id ? 1 : -1
        })

        huayBillItems?.sort(function (a, b) {
          return a.huayCategoryID > b.huayCategoryID ? 1 : -1
        })

        this.huayBillItems = huayBillItems

        this.swal.close()
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async getUserHuayBill() {
      this.swal.processing()

      try {
        const { data } = await this.axios.get(
          "report/bet/" + this.$route.params.userId,
          {
            params: {
              page: this.activePage,
              pageSize: this.itemsPerPage,
              ...this.columnFilterValue,
            },
          }
        )

        const resData = data.data

        resData.bills?.forEach((item) => {
          let statusClass = ""
          if (item.status == "BET_WIN") {
            statusClass = "positive"
          } else if (item.status == "BET_LOSE") {
            statusClass = "negative"
          }

          item._cellClasses = {
            sumBuyAmount: item.sumBuyAmount > 0 ? "positive" : "",
            sumDiscountAmount: item.sumDiscountAmount > 0 ? "positive" : "",
            sumAffiliateAmount: item.sumAffiliateAmount > 0 ? "positive" : "",
            sumWinAmount: item.sumWinAmount > 0 ? "positive" : "",
            status: statusClass,
          }

          item.sumWinAmount = this.util.formatMoneyNormal(item.sumWinAmount)
          item.sumBuyAmount = this.util.formatMoneyNormal(item.sumBuyAmount)
          item.createdAt = this.$date(item.createdAt).format(
            "DD/MM/YYYY HH:mm:ss"
          )
        })

        this.user = resData.user
        this.bills = resData.bills

        this.swal.close()
      } catch (e) {
        console.log(e)
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
        })
      }
    },
    paginationChange(val) {
      this.itemsPerPage = val

      this.getUserHuayBill()
    },
    updateActivePage(val) {
      if (
        parseInt(val) == parseInt(this.totalPage) &&
        this.bills &&
        this.bills.length != 0
      ) {
        this.totalPage++
      }

      this.activePage = val

      this.getUserHuayBill()
    },
    columnFilter() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        this.getUserHuayBill()
      }, 500)
    },
    async cancelBill(userHuayBill) {
      let confirm = await this.swal.confirm(
        "คืนโพย",
        "ท่านต้องการคืนโพยใช่หรือไม่"
      )
      if (!confirm) {
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "patch",
          url: "/users/bill/cancelled/" + userHuayBill.id,
          params: {
            userId: userHuayBill.userId,
          },
        })

        this.swal.swalSuccess()

        this.$store.dispatch("auth/forceReload")
      } catch (e) {
        console.log(e)
        this.swal.swalError(e.response.data.description)
      }
    },
  },
  mounted() {
    this.getUserHuayBill()
  },
}
</script>
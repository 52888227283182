<template>
  <div>
    <b-button size="sm" v-b-modal.modal-1>คำนวณ</b-button>
    <b-modal
      id="modal-1"
      size="xl"
      body-bg-variant="dark"
      body-text-variant="light"
      header-bg-variant="dark"
      header-text-variant="light"
      footer-bg-variant="dark"
      footer-text-variant="light"
      hide-footer
      title="คำนวณ"
    >
      <div class="row">
        <div class="form-group col-md-2 text-md-right">วันที่เริ่ม:</div>
        <div class="form-group col-md-2">
          <ValidationProvider rules="required" v-slot="v">
            <b-form-datepicker
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="th"
              v-model="form.openDate"
              placeholder="เลือกวัน"
              label-no-date-selected="ยังไม่เลือก"
              today-button
              reset-button
              close-button
              label-close-button="ปิด"
              label-reset-button="รีเซ็ต"
              label-today="วันนี้"
              label-today-button="วันนี้"
            ></b-form-datepicker>
            <input type="text" hidden v-model="form.openDate" />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group col-md-1 text-md-right">เวลา</div>
        <div class="form-group col-md-2">
          <ValidationProvider rules="required" v-slot="v">
            <b-form-timepicker
              locale="th"
              v-model="form.openTime"
              placeholder="เลือกเวลา"
              now-button
              reset-button
              label-close-button="ปิด"
              label-no-time-selected="ยังไม่เลือก"
              label-reset-button="รีเซ็ต"
              label-now-button="ตอนนี้"
            ></b-form-timepicker>
            <input type="text" hidden v-model="form.openTime" />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-2 text-md-right">ถึงวันที่:</div>
        <div class="form-group col-md-2">
          <ValidationProvider rules="required" v-slot="v">
            <b-form-datepicker
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              v-model="form.closeDate"
              locale="th"
              placeholder="เลือกวัน"
              label-no-date-selected="ยังไม่เลือก"
              today-button
              reset-button
              close-button
              label-close-button="ปิด"
              label-reset-button="รีเซ็ต"
              label-today="วันนี้"
              label-today-button="วันนี้"
            ></b-form-datepicker>
            <input type="text" hidden v-model="form.closeDate" />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group col-md-1 text-md-right">เวลา</div>
        <div class="form-group col-md-2">
          <ValidationProvider rules="required" v-slot="v">
            <b-form-timepicker
              locale="th"
              v-model="form.closeTime"
              placeholder="เลือกเวลา"
              now-button
              reset-button
              label-close-button="ปิด"
              label-no-time-selected="ยังไม่เลือก"
              label-reset-button="รีเซ็ต"
              label-now-button="ตอนนี้"
            ></b-form-timepicker>
            <input type="text" hidden v-model="form.closeTime" />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <button class="btn btn-primary" @click="getSumUserGetBet">คำนวณ</button>
      <div class="d-flex flex-column mt-3">
        <div>
          เดิมพัน:
          <span :class="{ negative: sumUserGameBet.sumStake < 0 }">{{
            util.formatMoney(sumUserGameBet.sumStake)
          }}</span>
        </div>
        <div>
          ชนะ:
          <span :class="{ positive: sumUserGameBet.sumPayout > 0 }">{{
            util.formatMoney(sumUserGameBet.sumPayout)
          }}</span>
        </div>
        <div>
          แพ้ชนะ:
          <span
            :class="{
              negative: sumUserGameBet.winLoss < 0,
              positive: sumUserGameBet.winLoss > 0,
            }"
            >{{ util.formatMoney(sumUserGameBet.winLoss) }}</span
          >
        </div>
      </div>
    </b-modal>
    <CDataTable
      :items="userGameBet.items"
      :fields="userGameBet.fields"
      :items-per-page-select="{ label: 'จำนวนแถว' }"
      :items-per-page.sync="userGameBet.itemsPerPage"
      :loading="userGameBet.tableLoading"
      :no-items-view="{
        noResults: 'ไม่พบข้อมูล',
        noItems: 'ไม่พบข้อมูล',
      }"
      :filter="false"
      :sorter="false"
      :column-fiter="false"
      dark
      border
      striped
      size="sm"
      add-table-classes="w-100 text-sm text-center"
      @:pagination-change="userGameBetPaginationChange"
    >
    </CDataTable>
    <CPagination
      :active-page="userGameBet.activePage"
      :double-arrows="false"
      :pages="userGameBet.totalPage"
      :dots="false"
      @update:activePage="userGameBetUpdateActivePage"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      isLoading: true,
      userGameBet: {
        fields: [
          {
            key: "id",
            label: "ID",
          },
          {
            key: "betId",
            label: "betID",
          },
          {
            key: "createdAt",
            label: "เวลาที่ทำรายการ",
          },
          {
            key: "name",
            label: "เกม",
          },
          {
            key: "payoutStatus",
            label: "รายละเอียด",
          },
          {
            key: "amount",
            label: "เครดิต",
          },
          {
            key: "afterBalance",
            label: "คงเหลือ",
          },
        ],
        items: [],
        itemsPerPage: 50,
        totalPage: 5,
        activePage: 1,
        tableLoading: false,
      },
      sumUserGameBet: {},
    }
  },
  methods: {
    async getUserGameBet() {
      this.swal.processing()

      try {
        const { data } = await this.axios({
          method: "get",
          url: "report/user-game-bet",
          params: {
            userId: this.$route.params.userId,
            page: this.userGameBet.activePage,
            pageSize: this.userGameBet.itemsPerPage,
          },
        })

        data.data?.forEach((item) => {
          item.createdAt = this.$date(item.createdAt).format(
            "DD/MM/YYYY HH:mm:ss"
          )
          item.stake = item.stake * -1
          item.payoutStatus =
            item.payoutStatus === "UNKNOWN" ? "BET" : item.payoutStatus
          let payoutStatusClass = "text-info"
          if (item.payoutStatus === "WIN") {
            payoutStatusClass = "positive"
          } else if (item.payoutStatus === "LOSE") {
            payoutStatusClass = "negative"
          }

          item.amount =
            item.betStatus === "OPEN" || item.betStatus === "VOID"
              ? item.stake
              : item.payout

          let amountClass = ""
          if (item.amount < 0) {
            amountClass = "negative"
          } else if (item.amount > 0) {
            amountClass = "positive"
          }

          item.amount = this.util.formatMoney(item.amount)

          item.name = item.name !== "" ? item.name : item.gameCode

          item._cellClasses = {
            payoutStatus: payoutStatusClass,
            amount: amountClass,
            afterBalance: "positive",
          }

          item.afterBalance = this.util.formatMoney(item.afterBalance)
        })

        this.userGameBet.items = data.data

        this.swal.close()
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    userGameBetPaginationChange(val) {
      this.userGameBet.itemsPerPage = val

      this.getUserGameBet()
    },
    userGameBetUpdateActivePage(val) {
      if (
        parseInt(val) == parseInt(this.userGameBet.totalPage) &&
        this.userGameBet.items &&
        this.userGameBet.items.length != 0
      ) {
        this.userGameBet.totalPage++
      }

      this.userGameBet.activePage = val

      this.getUserGameBet()
    },
    userGameBetColumnFilter() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        this.getUserGameBet()
      }, 500)
    },
    async getSumUserGetBet() {
      this.swal.processing()

      try {
        const { data } = await this.axios({
          method: "get",
          url: "report/sum/user-game-bet",
          params: {
            userId: this.$route.params.userId,
            startTime: this.$date(
              `${this.form.openDate} ${this.form.openTime}`
            ).format("YYYY-MM-DDTHH:mm:ssZ"),
            endTime: this.$date(
              `${this.form.closeDate} ${this.form.closeTime}`
            ).format("YYYY-MM-DDTHH:mm:ssZ"),
          },
        })

        this.sumUserGameBet = data.data

        this.swal.close()
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  mounted() {
    this.form.openDate = this.$date().format("YYYY-MM-DD")
    this.form.openTime = "00:00"
    this.form.closeDate = this.$date().format("YYYY-MM-DD")
    this.form.closeTime = "23:59"
    this.getUserGameBet()
  },
}
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CDataTable',{attrs:{"items":_vm.reportGameBetUser.items,"fields":_vm.reportGameBetUser.fields,"column-filter":{ external: _vm.reportGameBetUser.externalFilter },"items-per-page-select":{ label: 'จำนวนแถว' },"table-filter-value":_vm.reportGameBetUser.tableFilterValue,"column-filter-value":_vm.reportGameBetUser.columnFilterValue,"items-per-page":_vm.reportGameBetUser.itemsPerPage,"loading":_vm.reportGameBetUser.tableLoading,"noItemsView":{
      noResults: 'ไม่พบข้อมูล',
      noItems: 'ไม่พบข้อมูล',
    },"sorter":false,"pagination":"","hover":"","dark":"","border":"","size":"sm","add-table-classes":"text-center"},on:{"update:tableFilterValue":function($event){return _vm.$set(_vm.reportGameBetUser, "tableFilterValue", $event)},"update:table-filter-value":function($event){return _vm.$set(_vm.reportGameBetUser, "tableFilterValue", $event)},"update:columnFilterValue":function($event){return _vm.$set(_vm.reportGameBetUser, "columnFilterValue", $event)},"update:column-filter-value":[function($event){return _vm.$set(_vm.reportGameBetUser, "columnFilterValue", $event)},_vm.reportGameBetUserColumnFilter],"pagination-change":_vm.reportGameBetUserPaginationChange},scopedSlots:_vm._u([{key:"more",fn:function({ item }){return [_c('td',[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-1",modifiers:{"modal-1":true}}],staticClass:"mr-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.setReportGameBetUserItem(item)}}},[_vm._v("เพิ่มเติม")])],1)]}}])}),_c('CPagination',{attrs:{"active-page":_vm.reportGameBetUser.activePage,"double-arrows":false,"pages":_vm.reportGameBetUser.totalPage,"dots":false},on:{"update:activePage":_vm.reportGameBetUserUpdateActivePage}}),_c('b-modal',{attrs:{"id":"modal-1","size":"xl","body-bg-variant":"dark","body-text-variant":"light","header-bg-variant":"dark","header-text-variant":"light","footer-bg-variant":"dark","footer-text-variant":"light","hide-footer":"","title":"รายการเล่นเกม"}},[_c('CDataTable',{attrs:{"items":_vm.userGameBet.items,"fields":_vm.userGameBet.fields,"items-per-page-select":false,"items-per-page":_vm.userGameBet.itemsPerPage,"loading":_vm.userGameBet.tableLoading,"no-items-view":{
        noResults: 'ไม่พบข้อมูล',
        noItems: 'ไม่พบข้อมูล',
      },"filter":false,"sorter":false,"column-fiter":false,"dark":"","border":"","striped":"","size":"sm","add-table-classes":"w-100 text-sm text-center"},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.userGameBet, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.userGameBet, "itemsPerPage", $event)},":pagination-change":_vm.userGameBetPaginationChange}}),_c('CPagination',{attrs:{"active-page":_vm.userGameBet.activePage,"double-arrows":false,"pages":_vm.userGameBet.totalPage,"dots":false},on:{"update:activePage":_vm.userGameBetUpdateActivePage}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
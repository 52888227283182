<template>
  <div>
    <CDataTable
      :items="reportGameBetUser.items"
      :fields="reportGameBetUser.fields"
      :column-filter="{ external: reportGameBetUser.externalFilter }"
      :items-per-page-select="{ label: 'จำนวนแถว' }"
      :table-filter-value.sync="reportGameBetUser.tableFilterValue"
      :column-filter-value.sync="reportGameBetUser.columnFilterValue"
      :items-per-page="reportGameBetUser.itemsPerPage"
      :loading="reportGameBetUser.tableLoading"
      :noItemsView="{
        noResults: 'ไม่พบข้อมูล',
        noItems: 'ไม่พบข้อมูล',
      }"
      v-on:pagination-change="reportGameBetUserPaginationChange"
      v-on:update:column-filter-value="reportGameBetUserColumnFilter"
      :sorter="false"
      pagination
      hover
      dark
      border
      size="sm"
      add-table-classes="text-center"
    >
      <template #more="{ item }">
        <td>
          <b-button
            class="mr-2"
            size="sm"
            v-b-modal.modal-1
            @click="setReportGameBetUserItem(item)"
            >เพิ่มเติม</b-button
          >
        </td>
      </template>
    </CDataTable>
    <CPagination
      :active-page="reportGameBetUser.activePage"
      :double-arrows="false"
      :pages="reportGameBetUser.totalPage"
      :dots="false"
      v-on:update:activePage="reportGameBetUserUpdateActivePage"
    />
    <b-modal
      id="modal-1"
      size="xl"
      body-bg-variant="dark"
      body-text-variant="light"
      header-bg-variant="dark"
      header-text-variant="light"
      footer-bg-variant="dark"
      footer-text-variant="light"
      hide-footer
      title="รายการเล่นเกม"
    >
      <CDataTable
        :items="userGameBet.items"
        :fields="userGameBet.fields"
        :items-per-page-select="false"
        :items-per-page.sync="userGameBet.itemsPerPage"
        :loading="userGameBet.tableLoading"
        :no-items-view="{
          noResults: 'ไม่พบข้อมูล',
          noItems: 'ไม่พบข้อมูล',
        }"
        :filter="false"
        :sorter="false"
        :column-fiter="false"
        dark
        border
        striped
        size="sm"
        add-table-classes="w-100 text-sm text-center"
        @:pagination-change="userGameBetPaginationChange"
      >
      </CDataTable>
      <CPagination
        :active-page="userGameBet.activePage"
        :double-arrows="false"
        :pages="userGameBet.totalPage"
        :dots="false"
        @update:activePage="userGameBetUpdateActivePage"
      />
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      reportGameBetUser: {
        fields: [
          {
            key: "id",
            label: "#ID",
            _style: "width: 1%",
          },
          {
            key: "productName",
            label: "ค่ายเกม",
            filter: false,
          },
          {
            key: "gameName",
            label: "เกม",
            filter: false,
          },
          {
            key: "beforeBalance",
            label: "ตั้งต้น",
            filter: false,
          },
          {
            key: "stake",
            label: "พนัน",
            filter: false,
          },
          {
            key: "payout",
            label: "ชนะ",
            filter: false,
          },
          {
            key: "afterBalance",
            label: "คงเหลือ",
            filter: false,
          },
          {
            key: "createdAt",
            label: "เริ่มเล่น",
            filter: false,
          },
          {
            key: "updatedAt",
            label: "สิ้นสุด",
            filter: false,
          },
          {
            key: "more",
            label: "",
            filter: false,
          },
        ],
        tableFilterValue: "",
        columnFilterValue: {},
        sorterValue: {},
        itemsPerPage: 10,
        totalPage: 5,
        activePage: 1,
        tableLoading: false,
        externalFilter: true,
        items: [],
        item: {},
      },
      userGameBet: {
        fields: [
          {
            key: "id",
            label: "ID",
          },
          {
            key: "betId",
            label: "betID",
          },
          {
            key: "createdAt",
            label: "เวลาที่ทำรายการ",
          },
          {
            key: "name",
            label: "เกม",
          },
          {
            key: "payoutStatus",
            label: "รายละเอียด",
          },
          {
            key: "amount",
            label: "เครดิต",
          },
          {
            key: "afterBalance",
            label: "คงเหลือ",
          },
        ],
        items: [],
        itemsPerPage: 50,
        totalPage: 5,
        activePage: 1,
        tableLoading: false,
      },
    }
  },
  methods: {
    setPage(val) {
      this.$route.query.page = val
    },
    setReportGameBetUserItem(item) {
      this.reportGameBetUser.item = item

      this.getUserGameBet()
    },
    async getUserGameBet() {
      this.swal.processing()

      try {
        const { data } = await this.axios({
          method: "get",
          url: "report/user-game-bet",
          params: {
            gameProductCode: this.reportGameBetUser.item.gameProductCode,
            gameCode: this.reportGameBetUser.item.gameCode,
            sessionToken: this.reportGameBetUser.item.sessionToken,
            page: this.userGameBet.activePage,
            pageSize: this.userGameBet.itemsPerPage,
          },
        })

        data.data?.forEach((item) => {
          item.createdAt = this.$date(item.createdAt).format(
            "DD/MM/YYYY HH:mm:ss"
          )
          item.stake = item.stake * -1
          item.payoutStatus =
            item.payoutStatus === "UNKNOWN" ? "BET" : item.payoutStatus
          let payoutStatusClass = "text-info"
          if (item.payoutStatus === "WIN") {
            payoutStatusClass = "positive"
          } else if (item.payoutStatus === "LOSE") {
            payoutStatusClass = "negative"
          }

          item.amount =
            item.betStatus === "OPEN" || item.betStatus === "VOID"
              ? item.stake
              : item.payout

          let amountClass = ""
          if (item.amount < 0) {
            amountClass = "negative"
          } else if (item.amount > 0) {
            amountClass = "positive"
          }

          item.amount = this.util.formatMoney(item.amount)

          item.name = item.name !== "" ? item.name : item.gameCode

          item._cellClasses = {
            payoutStatus: payoutStatusClass,
            amount: amountClass,
            afterBalance: "positive",
          }

          item.afterBalance = this.util.formatMoney(item.afterBalance)
        })

        this.userGameBet.items = data.data

        this.swal.close()
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    userGameBetPaginationChange(val) {
      this.userGameBet.itemsPerPage = val

      this.getUserGameBet()
    },
    userGameBetUpdateActivePage(val) {
      if (
        parseInt(val) == parseInt(this.userGameBet.totalPage) &&
        this.userGameBet.items &&
        this.userGameBet.items.length != 0
      ) {
        this.userGameBet.totalPage++
      }

      this.userGameBet.activePage = val

      this.getUserGameBet()
    },
    userGameBetColumnFilter() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        this.getUserGameBet()
      }, 500)
    },
    async getReportGameBetUser() {
      this.swal.processing()

      try {
        const { data } = await this.axios.get("report/game-bet-user", {
          params: {
            userId: this.$route.params.userId,
            page: this.reportGameBetUser.activePage,
            pageSize: this.reportGameBetUser.itemsPerPage,
            ...this.reportGameBetUserUpdateActivePage.columnFilterValue,
          },
        })

        const resData = data.data

        resData?.forEach((item) => {
          item._cellClasses = {}

          item.beforeBalance = this.util.formatMoneyNormal(item.beforeBalance)
          item.afterBalance = this.util.formatMoneyNormal(item.afterBalance)
          item.payout = this.util.formatMoneyNormal(item.payout)
          item.stake = this.util.formatMoneyNormal(item.stake)
          item.createdAt = this.$date(item.createdAt).format(
            "DD/MM/YYYY HH:mm:ss"
          )
          item.updatedAt = this.$date(item.updatedAt).format(
            "DD/MM/YYYY HH:mm:ss"
          )
        })

        this.reportGameBetUser.items = resData

        this.swal.close()
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    reportGameBetUserPaginationChange(val) {
      this.reportGameBetUser.itemsPerPage = val

      this.getReportGameBetUser()
    },
    reportGameBetUserUpdateActivePage(val) {
      if (
        parseInt(val) == parseInt(this.reportGameBetUser.totalPage) &&
        this.reportGameBetUser.items &&
        this.reportGameBetUser.items.length != 0
      ) {
        this.reportGameBetUser.totalPage++
      }

      this.reportGameBetUser.activePage = val

      this.getReportGameBetUser()
    },
    reportGameBetUserColumnFilter() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        this.getReportGameBetUser()
      }, 500)
    },
  },
  mounted() {
    this.getReportGameBetUser()
  },
}
</script>